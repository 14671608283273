<!--政策-->
<template>
  <div class="talent-page-content">
    <t-nav
      :list="navData.navList"
      :name="navData.title"
      :active="navData.navList[0]"
      @changeNav="changeNav"
    />
    <div class="talent-data-content" v-show="!detailShow">
      <div class="talent-data-title">全部需求</div>
<!--      <div class="talent-data-title">{{ activeName }}</div>-->
      <div class="talent-data-search">
        <a-input-search
          class="talent-nav-search-input"
          placeholder="请输入要搜索的内容"
          enter-button="搜索"
          size="large"
          @search="onSearch"
        />
<!--        <a-button class="button1"  type="danger" round>发布成果/专利</a-button>-->
      </div>
      <div class="img-noData" v-if="list.length===0"><img src="@/assets/image/noData.png" alt="" /></div>
      <div class="talent-data-list" v-if="list.length>0">
<!--        <div class="gov-item" style="height:5px;background-color:	#F5F5F5">
          <div class="gov-title" >名称</div>
          <div class="gov-date" style="padding-right: 30px">操作</div>
        </div>-->
        <div class="gov-span"  v-for="item in list" :key="item.id" @click="showDetail(item.id)">
        <div  class="gov-item">
          <div class="gov-title">
            <span class="gov-spam-rz content3">{{item.title }}</span><br/>
            单位：{{ item.unitExternal }}<br/>
            领域：<span v-for="itemA in technicalFieldList" :key="itemA.id" ><span v-if="item.technicalField.indexOf(itemA.id)>=0">{{ itemA.name}}；</span></span><br/>
            状态：<span class="yellow" v-text="item.type===null?'':item.type==0?'对接中':'对接中'">{{ item.type }}</span>
          </div>
          <div class="gov-date" style="text-align: center;color:#ff9a02;width: 100px">
            {{item.rewardCurrencyType===null?'':item.rewardCurrencyType==1?'价格面议':'货币'}}
             <br/>
            <a-button v-if="$store.state.user.isPermissions()==='1'||$store.state.user.isPermissions()===undefined" class="button2"  type="danger" @click.stop="showForm(item)"  round>立即申请</a-button>
          </div>
        </div>
        </div>

        <a-pagination  :show-total="total => `共 ${total} 条`"   show-quick-jumper v-if="total>5" :pageSize="pageSize"  v-model="pageNum" :total="total" @change="change" show-less-items />
      </div>
    </div>
    <div class="gov-detailD" v-show="detailShow">
      <span class="yellow text" style="float: right" v-text="selDetail.rewardCurrencyType===null?'':selDetail.rewardCurrencyType==1?'价格面议':'货币'"></span>
<!--      <a-page-header
          style="float: right"
          title="返回"
          @back="detailShow=false"
      />-->
      <div class="gov-detail-top">
        <div class="gov-detail-top-title"><span class="gov-detail-top-span" v-text="selDetail.type===null?'':selDetail.type==0?'对接中':'对接中'"></span>{{ selDetail.title }}</div>
      </div>
      <div class="gov-detail-top" style="padding-bottom: 50px">
        <div class="gov-detail-top-date " style="float: right" >
          <a-button v-if="$store.state.user.isPermissions()==='1'||$store.state.user.isPermissions()===undefined"  class="button2" @click="showForm(selDetail)"  type="danger" round>立即申请</a-button></div>
        <div class="gov-detail-top-date"><span class="center-span">发布人（单位）：</span>{{ selDetail.personExternal}}（{{ selDetail.unitExternal }}）</div>
        <div class="gov-detail-top-date" ><span class="center-span">需求类型：</span>{{ categoryName }}</div>
        <div class="gov-detail-top-date"><span class="center-span">需求经费：</span><span v-text="selDetail.rewardCurrencyType===null?'':selDetail.rewardCurrencyType===1?'价格面议':selDetail.payAmount+'（元）'"></span></div>
        <div class="gov-detail-top-date" ><span class="center-span">技术领域：</span>{{technicalFieldName}}</div>
        <div class="gov-detail-top-date"><span class="center-span">截止时间：</span>{{ selDetail.demandPeriod}}</div>
        <br/>
      </div >
      <span class="text-heda">需求描述</span>
      <div class="gov-detail-content" v-html="selDetail.description">内容</div>
    </div>
    <applicationForm  v-if="visible" tebel="需求名称" :demandName="demandName" :demandId="demandId" :visible="visible"
                      :type="0"/>
  </div>
</template>
<script>
import {getPoliticsList,selPolitics} from "../../api/company/demand_information";
import {getDictType,getDictTypeById} from "../../api/company/dictType";
import applicationForm from "../../views/components/applicationForm.vue";
import {getType} from "../../utils/auth";
export default {
  components:{
    applicationForm
  },
  data() {
    return {
      isUserType:undefined,
      demandName:"",
      demandId:null,
      visible:false,
      categoryName:'',
      active:"",
      form:{
        category:null,
        title:'',
      },
      pageNum:1,
      pageSize:5,
      total:10,
      navData: {
        navList: [
          { name: "全部需求", id: null},
        ],
        title: "需求中心",
        default: 0,
      },
      technicalFieldList:[],
      activeName: "全部需求",
      detailShow: false,
      current: 2,
      technicalFieldName:null,
      selDetail: {
        title:
          "湖南省实施《中华人民共和国促进科技成果转化法》办法（2019年修订）",
        publishTime: "2011/11/11",
        content:
          "产养殖数据库，立足于无人船平台，瞄准服务水产养殖全产业链，用大数据的思维，指导水产企业养殖品类与规模、指导水产养殖原料供应商生产计划、指导水产产品下游企业生产计划、支撑国家水产养殖行业精准扶持、支撑水产养殖市场政策调控，实现了公司从产品经济至数据经济",
      },
      list:[],
    };
  },
  methods: {
    showForm(item){
      if(this.isUserType===undefined||this.isUserType===null){
        this.$parent.login();
        return
      }
      //console.log(this.visible)
      this.demandName= item.title;
      this.demandId=item.id;
      this.visible=true;
    },
    change(){
      getPoliticsList({pageNum:this.pageNum,pageSize:this.pageSize,form:this.form}).then(res=>{
        //console.log(res);
        this.list=res.data.list;
        this.total=res.data.total;
      });
    },
   async changeNav(item) {
      this.detailShow = false;
      //console.log(this.detailShow)
      //todo 切换分类 重新加载数据
      this.activeName = item.name;
      //console.log(item);
      this.form.category=item.id;
     await getPoliticsList({pageNum:1,pageSize:this.pageSize,form:this.form}).then(res=>{
        //console.log(res);
        this.list=res.data.list;
        this.total=res.data.total;
      });
    },
    onSearch(val) {
      this.form.title=val;
      getPoliticsList({pageNum:1,pageSize:this.pageSize,form:this.form}).then(res=>{
        //console.log(res);
        this.list=res.data.list;
        this.total=res.data.total;
      });
    },
   async showDetail(id) {
      //console.log(id);
      this.detailShow = true;
     await selPolitics({id:id}).then(res=>{
          //console.log(res);
          this.selDetail=res.data;
          //console.log(this.selDetail,"this.selDetail");
          let aaa='';
       for (let i = 0; i <this.technicalFieldList.length ; i++) {
         if(this.selDetail.technicalField.indexOf(this.technicalFieldList[i].id)>=0){
           if(aaa===''){
             aaa+=this.technicalFieldList[i].name;
           }else {
             aaa+="/"+this.technicalFieldList[i].name;
           }
         }
       }
       this.technicalFieldName=aaa;
       //console.log(this.technicalFieldName,aaa);
      })
     getDictTypeById(this.selDetail.category).then(res=>{
       this.categoryName=res.msg;
     })
    },
    async  init(){
      //console.log(getType(),"===========================")
      this.isUserType=getType();
      //console.log(this.isUserType,"2===========================")
      getDictType(30).then(res=>{
        this.technicalFieldList=res.data;
      })
      //console.log(this.$route.query.key)
      let key=this.$route.query.key===undefined?'':this.$route.query.key;
      this.form.title=key;
    await  this.changeNav({id:null,name:null})
      this.form.title='';
    }
  },
  mounted() {
    this.init()
  }
};
</script>
<style lang="scss" scoped>
.gov-detail-top-span{
  background:  #ff9a02;
  font-size: 17px;
  color: #ffffff;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 8px;
}
.talent-data-search {
  margin: 25px 0;
  .talent-nav-search-input {
    width: 419px;
    .ant-btn {
      background: #ff9a02;
      border-color: #ff9a02;
      border-radius: 0;
    }
  }
}
.talent-data-list {
  .gov-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    padding: 10px 0;
    border-bottom: 2px solid #efefef;
    cursor: pointer;
    .gov-title {
      width: 900px;
      padding-left: 30px;
      .gov-spam-rz {
        color: #333333;
        line-height: 36px;
      }
    }
    .gov-date {
      color: #333333;
    }
  }
  .gov-span :hover{
    .gov-spam-rz {
      color : #044fc6;
    }
  }
}
.gov-detailD{
  width: 75%;
  background: #f8fbff;
  padding: 40px 30px;
  color: #666666;
  position: relative;
  .gov-detail-top {
    line-height: 36px;
    border-bottom: 2px solid #efefef;
    padding-bottom: 20px;
    .gov-detail-top-title {
      color: #333333;
      font-weight: 600;
      font-size: 20px;
    }
    .gov-detail-top-date {
      font-size: 16px;
    }
  }
  .gov-detail-content {
    margin-top: 10px;
    line-height: 36px;
  }
}
</style>
