<template>
  <div class="">
    <a-modal v-model="visible"  title="提交申请" ok-text="提交" cancel-text="取消" @ok="hideModal" @cancel="noModal()" >
      <div class="applicationForm">
      <a-form-model  ref="form" :model="form" :rules="rules" label-width="auto"  :label-col="{ span:5 }"  :wrapper-col="{ span: 18}">
        <a-form-model-item :label="tebel" prop="img">
          <span style="font-size: 16px;color:#333333 " v-text="demandName"></span>
        </a-form-model-item>
        <a-form-model-item label="联系人姓名" prop="contactName">
          <a-input v-model="form.contactName" max-length="255" placeholder="请输入联系人姓名"></a-input>
        </a-form-model-item>
        <a-form-model-item label="联系邮箱" prop="contactEmail">
          <a-input v-model="form.contactEmail" max-length="255" placeholder="请输入邮箱"></a-input>
        </a-form-model-item>
        <a-form-model-item label="联系人手机" max-length="11" prop="contactPhone">
          <a-input v-model="form.contactPhone" placeholder="请输入联系人手机"></a-input>
        </a-form-model-item>
        <a-form-model-item label="联系人电话" max-length="12" prop="contactNumber">
          <a-input v-model="form.contactNumber" placeholder="请输入联系人电话"></a-input>
        </a-form-model-item>
      </a-form-model>
     </div>
    </a-modal>
  </div>
</template>
<script>
import {addForm} from '../../api/company/applicationForm'
export default {
  name: 'applicationForm',
  components: {},
  props: {
    visible:Boolean,
    demandId:Number,
    demandName:String,
    tebel:String,
    type:Number
  },
  watch:{
  },
    data() {
    return {
      form: {
        id:null,
        projectId:null,
        projectName:null,
        contactName:null,
        contactEmail:null,
        contactPhone:null,
        contactNumber:null,
        type:null,
        applicant_type:null
      },
      rules: {
        contactName: [{required: true, message: '请输入联系人姓名', trigger: 'blur'}],
        contactEmail: [{required: true, message: '请输入邮箱', trigger: 'blur'},{pattern:/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,message: '请输入正确的邮箱！',trigger: 'blur' }],
        contactPhone: [{required: true, message: '请输入联系人手机', trigger: 'blur'},{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号！' ,trigger: 'blur'}],
        contactNumber: [{ pattern: /^(0[0-9]{2,3})([\\-]{0,1})([2-9][0-9]{4,7})+(\\-[0-9]{1,4})?$/, message: '请输入正确的座机号！' ,trigger: 'blur'}],
      }
    }
  },
  methods: {
    hideModal(e) {
      //console.log(this.demandId,e)
      this.$refs.form.validate( async valid =>{
        if (valid) {
          this.form.projectId=this.demandId;
          this.form.projectName=this.demandName;
          this.form.type=this.type;
          addForm(this.form).then(()=>{
            this.$message.success("已提交，请耐心等候")
            this.visible = false;
            this.$parent.visible=false;
           // this.$parent.showFormB();
          //  this.$emit('showFormB');
          })
        }
      })
    },
    noModal(){
     //  this.$emit('showFormB');
     // this.$parent.showFormB();
      this.$parent.visible=false;
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.ant-modal-root{
  .applicationForm {
      background: #FFFFFF;
    .ant-input {
      width: 435px;
      height: 46px;
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
    }

    .ant-form-item {
      /*margin-bottom: 15px;*/
    }
  }
}
</style>
<style lang="scss" >

  .ant-modal-root{
    .ant-modal-content {
      width: 650px;
      /*height: 475px;*/
      margin-top: 200px;
      padding-bottom: 20px;
      background: #FFFFFF;

      .ant-modal-header {
        width: 650px;
        height: 59px;
        background: #075FEA;
        text-align: center;

        .ant-modal-title {
          font-size: 22px;
          font-family: Hiragino Sans GB;
          color: #FFFFFF;
        }
      }

      .ant-modal-close {
        color: #e0ebfd;
      }
    }

    .ant-modal-body {
      padding-bottom: 0px;
    }

    .ant-modal-footer {
      border-top-width: 0px;

      .ant-btn {
        font-size: 18px;
        width: 119px;
        height: 42px;
        color: #075FEA;
      }

      .ant-btn-primary {
        margin-left: 20px;
        margin-right: 180px;
        background: #075FEA;
        color: #FFFFFF;
      }
    }

    #rcDialogTitle37 {
      text-align: center !important;
    }
  }
</style>